/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { useLocation, Link, useNavigate, Outlet } from 'react-router-dom';
import cn from 'classnames';
import useOutsideClick from 'hooks/useOutSideClick';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { commonAxios } from 'utils/commonAxios';
import Dropdown from 'components/common/form/Dropdown';
import AlimPopup from 'components/common/alim/AlimPopup';
// redux
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { persistor } from 'index';
// mobix
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import * as store from 'stores';

import { PATHAUTH, PATHLOCALROUP, PATHCOMMON, PATHCOAGENCY, PATHMYINFO, SSO_PATHS, PATHALLPOSTS } from 'utils/path';

// config
import * as Config from 'configs/configs';

import Styles from 'styles/layout.module.css';
import FooterStyles from 'styles/footer.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Cookies from 'js-cookie';
import { useCommonStore } from 'stores/common';
import { moveMain, onClose, makeForm } from 'utils/common';
// import { urlAipartner, urlPlusIB, urlPlus } from 'configs/configs.js';

// 팝업 관련 component
import { GTM_CLASS } from 'pages/GTMTracker';
import ImagePopup from './ImagePopup';
import TextPopup from './TextPopup';
import BottomPopup from './BottomPopup';

interface INoticeMessage {
  id: number | null;
  title: string | null;
  content: string | null;
  created_at: string | null;
  notice_type: string | null;
}

/**
 * @description App Layout F.C
 * @Class
 * @category components
 * @subcategory layout
 * @component
 * @returns {JSX.Element}
 */
const Layout = observer(() => {
  const navigate = useNavigate();

  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);
  const isVerified = useAppSelector((state: RootState) => state.user.brokerVerified);
  const infoMe = useAppSelector((state: RootState) => state.user.me);

  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchWord: string | null = searchParams.get('searchMain');
  const serviceCode: string = searchParams.get('serviceCode')!;

  // mobx
  const { commonstore } = useCommonStore();
  const { authStore } = store;
  // const { isLogin, currentUser } = authStore;
  // console.log('isVerified==>', isVerified);
  const brokerVerifiedAt = !isVerified
    ? null
    : infoMe?.verify
    ? infoMe?.verify?.created_at
    : currentUser.verify
    ? currentUser?.verify?.created_at
    : null;

  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const path = location.pathname;

  const [searchKeyword, setSearchKeyWord] = useState('');
  const [searchon, setSearchon] = useState('');
  const [container, setContainer] = useState('');

  // alim
  const [showAlim, setShowAlim] = useState(false);
  const [unReadAlimCount, setUnReadAlimCount] = useState(null);
  const initLastNotice = {
    id: null,
    title: '',
    content: '',
    created_at: '',
    notice_type: '',
  };
  const [lastNotice, setLastNotice] = useState<INoticeMessage | any>(initLastNotice);
  const sessionOutRef = useRef<any>(null);
  const agent = navigator.userAgent.toLowerCase();

  /**
   * @description 마운트될때, notice 가져오기
   */
  useEffect(() => {
    const getData = async () => {
      if (!lastNotice?.id) await getNotice();
    };
    getData();
  }, []);

  /**
   * @description 로그인이후, 알림카운트를 못가져왔을경우 api 호출
   */
  useEffect(() => {
    setDropdownVisibility(false);
    const getData = async () => {
      if (isLogin && !unReadAlimCount) await getUnleadAlimCount();
    };
    getData();
  }, [isLogin]);

  useEffect(() => {
    const params = new URLSearchParams(location?.search || '');
    const queryGroupId = params?.get?.('groupId') || '';
    const queryPostId = params?.get?.('postId') || '';
    const queryId = params?.get?.('id') || '';
    const queryCode = params?.get?.('code') || '';

    const parsePath: any = {
      '/localgroup': '/localgroup',
      '/allposts/free-board': '/allposts/free-board',
      '/allposts/issue': '/allposts/issue',
      '/mygroup/group': '/mygroup/group/',
      '/mygroup/injoin': '/groupcode/:code',
      '/mygroup/member': '/groupmembersearch/',
      '/mygroup/settingmy': '/groupsetting/',
      '/mygroup/settingadmin': '/groupsetting/',
      '/mygroup/boardnotice': '/groupnoticelist/',
      '/search': '/search',
      '/myboard-coagency': '/myboard-coagency',
      '/myboard-comment': '/myboard-comment',
      '/bookmark-board': '/bookmark-board',
      '/myboard-lookingfor': '/myboard-lookingfor',
      '/myboard-board': '/myboard-board',
      '/myinfo': '/myinfo',
      '/help': '/help',
      '/qna': '/qna',
      '/bookmark-coagency': '/bookmark-coagency',
      '/bookmark-lookingfor': '/bookmark-lookingfor',
      '/mygroup/grouppost': '/mygroup/grouppost',
      '/allposts': '/allposts',
    };

    if (agent.includes('aipartnernet')) {
      if (location.pathname === '/') {
        moveMain(0, '');
        onClose();
      }
      if (location.pathname.includes('localgroup') || location.pathname.includes('/search')) {
        // 메인 홈으로 이동(지역모임)
        moveMain(1, '');
        onClose();
      } else if (location.pathname.includes('/mygroup/injoin')) {
        if (queryCode !== '') {
          window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}/groupcode/${queryCode}`);
        }
      } else if (
        location.pathname.includes('/coagency-coagency') ||
        location.pathname.includes('/coagency-lookingfor')
      ) {
        window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}/guide?type=coagency`);
      } else if (location.pathname.includes('/help') || location.pathname.includes('/qna')) {
        window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}/guide?type=etc`);
      } else if (location.pathname.includes('/mygroup/grouppost') || location.pathname.includes('/allpost')) {
        // 게시글 상세 페이지 => postId 또는 groupId가 없을 경우 전체게시판으로 이동
        if (queryGroupId !== '') {
          window.location.replace(
            `${import.meta.env.VITE_APP_DOMAIN}/group-postdetail/${queryGroupId}/${
              queryPostId !== '' ? queryPostId : queryId
            }?type=web`,
          );
        } else if (queryPostId !== '' || queryId !== '') {
          window.location.replace(
            `${import.meta.env.VITE_APP_DOMAIN}/postdetail/${queryPostId !== '' ? queryPostId : queryId}?type=web`,
          );
        } else {
          moveMain(3, 'type=free-board');
        }
      } else if (location.pathname.includes('/mygroup/boardnotice')) {
        if (queryPostId !== '') {
          window.location.replace(
            `${import.meta.env.VITE_APP_DOMAIN}/group-postdetail/${queryId}/${queryPostId}?type=web`,
          );
        } else {
          window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}/groupnoticelist/${queryId}`);
        }
      } else if (location.pathname.includes('/allposts/free-board') || location.pathname.includes('/allposts/issue')) {
        // 게시판 이동
        moveMain(3, location.pathname.includes('/allposts/free-board') ? 'type=free-board' : 'type=issue');
        onClose();
      } else if (
        location.pathname.includes('/myboard-coagency') ||
        location.pathname.includes('/myboard-comment') ||
        location.pathname.includes('/bookmark-board') ||
        location.pathname.includes('/myboard-lookingfor') ||
        location.pathname.includes('/myboard-board') ||
        location.pathname.includes('/myinfo')
      ) {
        moveMain(4, '');
        onClose();
      } else if (
        location.pathname.includes('/bookmark-coagency') ||
        location.pathname.includes('/bookmark-lookingfor')
      ) {
        window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}/feed/bookmarks`);
      } else if (parsePath?.[location.pathname]) {
        if (queryId !== '') {
          if (
            parsePath[location.pathname].includes('groupsetting') ||
            parsePath[location.pathname].includes('groupmembersearch')
          ) {
            window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}${parsePath[location.pathname]}${queryId}`);
          } else {
            window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}${parsePath[location.pathname]}?id=${queryId}`);
          }
        } else {
          window.location.replace(`${import.meta.env.VITE_APP_DOMAIN}${parsePath[location.pathname]}`);
        }
      }
    }
  }, []);

  /** bg gray체크할것! */
  useEffect(() => {
    if (
      path.includes(PATHLOCALROUP.MAKE) ||
      path.includes('/auth') ||
      path === SSO_PATHS.SIGNUP_COMPLETE ||
      path === PATHLOCALROUP.MAKESELECT
    ) {
      setContainer(CommonStyles['bg-gray']);
    }
    if (!isLogin) {
      if (path === '/help' || path === '/qna' || path === '/terms' || path === '/privacy-policy') {
        setContainer(CommonStyles.footer);
      } else if (path === SSO_PATHS.SIGNUP_COMPLETE) {
        setContainer(CommonStyles['bg-gray']);
      } else if (path === '/') {
        setContainer(cn(CommonStyles.main, CommonStyles.mainOnly));
      } else {
        setContainer(CommonStyles.main);
      }
    }
    return () => setContainer('');
  }, [path, isLogin]);

  useEffect(() => {
    const _lastNotice = toJS(commonstore?.noticeLast!);
    setLastNotice(_lastNotice);
  }, [commonstore?.noticeLast]);

  useEffect(() => {
    if (searchKeyword && searchWord) {
      setSearchon(Styles.on);
      commonstore.setSearchPalcehoder(searchWord);
    }
  }, [searchWord]);

  /**
   * @description scroll to top
   * @returns {void}
   */
  const scrollToTop = (): void => {
    commonstore.setSearchPalcehoder('');
    setSearchKeyWord('');
    setShowAlim(false);
    if (window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (!localStorage.getItem('searchLimit') || localStorage.getItem('searchLimit') === 'undefined') {
      localStorage.setItem('searchLimit', '50');
    }
    if (localStorage.getItem('showDetail')) {
      localStorage.removeItem('showDetail');
    }
    if (localStorage.getItem('Co-searchState')) {
      localStorage.removeItem('Co-searchState');
    }
    if (localStorage.getItem('Look-searchState')) {
      localStorage.removeItem('Look-searchState');
    }
  };

  /**
   * @description dropdown outside click handler
   * @returns {void}
   */
  const handleClickDropDownOutside = () => setDropdownVisibility(false);
  const dropdownRef = useOutsideClick(handleClickDropDownOutside);

  /**
   * @description search input outside click handler
   * @returns {void}
   */
  const handleClickSearchOutside = () => setSearchon('');
  const searchRef = useOutsideClick(handleClickSearchOutside);

  /**
   * @description 검색 on
   * @returns {Promise<void>}
   */
  const onReadySearch = useCallback(async () => {
    setSearchon(Styles.on);
  }, []);

  /**
   * @description 검색 input change handle
   * @returns {Promise<void>}
   */
  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setSearchKeyWord(e.target.value);
    commonstore.setKeyword(e.target.value);
  };

  /**
   * @description 검색 submit handler
   * @returns {void}
   */
  const onhandleSubmit = useCallback(() => {
    if (commonstore.iskeyword) {
      if (window.scrollY > 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      navigate(`/search?searchType=default&searchMain=${commonstore.iskeyword}`);
    }
    setSearchon('');
  }, [commonstore.iskeyword]);

  /**
   * @description keypress handler
   * @returns {void}
   */
  const handleKeyPress = (event: React.MouseEvent<HTMLButtonElement> | any) => {
    if (event.key === 'Enter') {
      onhandleSubmit();
    }
  };

  /**
   * @description logout
   * @description mobx currentUser clear,  redux clear, sso_session_expire 호출
   * @returns {void}
   */
  const logout = (): void => {
    authStore.resetCurrentUser();
    persistor.purge();
    navigate('/'); // 없이 root로 동작하지만 404페이지 제거
    if (import.meta.env.MODE !== 'localdev') {
      setTimeout(() => {
        makeForm(Config.SSO_SESSION_EXPIRE, []);
      }, 100);
    }
  };

  /**
   * @description 안읽은 알림갯수 가져오기 api call
   * @returns {Promise<void>}
   */
  const getUnleadAlimCount = async (): Promise<void> => {
    await commonAxios
      .get('/my/notices/count')
      .then((res) => {
        console.log('res getUnleadAlimCount', res);
        if (res && res.status === 200) {
          setUnReadAlimCount(res.data?.count);
        }
      })
      .catch((err) => {
        console.error('error', err);
        if (err && err.response?.data) {
          console.error(err.response.data.message);
        }
      });
  };

  /**
   * @description 공지사항 가져오기
   * @returns {Promise<void>}
   */
  const getNotice = async (): Promise<void> => {
    const params = {
      keyword: '',
      page_size: 10,
      page: 1,
    };
    // console.log('params===>', params);
    const body = JSON.stringify(params);
    await commonAxios
      .post(Config.API_URL.POST_NOTICE_LIST, body)
      .then((res) => {
        console.log('res 공지사항 handleSearch end===>', res);
        if (res && (res.status === 200 || res.status === 201)) {
          const _data = _.sortBy(res.data.data, 'created_at').reverse();
          console.log('_data=====>', _data);
          const _lastNotice = _data[0];
          setLastNotice(_lastNotice);
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  // 현재 시간을 쿠키와 로컬 스토리지에 저장
  // 쿠키는 브라우저를 종료하지 않는한 계속 유지되고, 로컬 스토리지는 브라우저를 종료해도 유지된다.
  // 브라우저를 끄면 쿠키는 사라지고, 로컬 스토리지는 사라지지 않는다.
  // 브라우저 종료 후 다시 실행하면 쿠키는 사라지고, 로컬 스토리지는 유지된다.
  // 쿠키 값과 로컬스토리지 값 비교하여 다를 경우 로그아웃 시킨다.
  // location.pathname이 변경될 때마다 useEffect 훅 내의 코드가 실행된다.
  useEffect(() => {
    const currentTime = new Date().toString();
    let cookieTime = Cookies.get('currentTime');
    let loginTime = localStorage.getItem('loginCookie');

    // 현재 시간을 쿠키에 저장
    if (!cookieTime) {
      Cookies.set('currentTime', currentTime);
      cookieTime = currentTime;
    }

    // 현재 시간을 로컬 스토리지에 저장
    if (!loginTime) {
      localStorage.setItem('loginCookie', currentTime);
      loginTime = currentTime;
    }

    // 예시 데이터 : 콘솔에 현재 시간 표시
    // console.log('쿠키 --- 현재 시간:', cookieTime);
    // console.log('로컬 스토리지 --- 로그인 시간:', loginTime);
    // 쿠키 --- 현재 시간: Tue Mar 19 2024 14:45:59 GMT+0900 (한국 표준시)
    // 로컬 스토리지 --- 로그인 시간: Tue Mar 19 2024 14:45:59 GMT+0900 (한국 표준시)

    // cookieTime와 loginTime 비교해서 다르면 localstorage 비워준다.
    if (cookieTime !== loginTime) {
      // 로그아웃 처리
      localStorage.clear();
      Cookies.remove('currentTime');
      authStore.resetCurrentUser();
      persistor.purge(); // 리덕스 초기화

      console.log('cookieTime와 loginTime이 다르므로 로컬 스토리지를 비웠습니다.');
      window.location.href = import.meta.env.VITE_SERVICE_URL;
    }
  }, [location]); // 페이지의 URL이 변경될 때마다 useEffect 훅 내의 코드가 실행됩니다.

  // // // 팝업 관련 코드 추가 (시작)
  const [imageArray, setImageArray] = useState([]);
  const [imageUrlArray, setImageUrlArray] = useState([]);
  const [imageUrlArrayLength, setImageUrlArrayLength] = useState([]);

  const [textArray, setTextArray] = useState([]);
  const [textTitleArray, setTextTitleArray] = useState([]);
  const [textUrlArray, setTextUrlArray] = useState([]);
  const [textArrayLength, setTextArrayLength] = useState([]);

  const [bottomArray, setBottomArray] = useState([]);
  const [bottomTitleArray, setBottomTitleArray] = useState([]);
  const [bottomUrlArray, setBottomUrlArray] = useState([]);
  const [bottomArrayLength, setBottomArrayLength] = useState([]);

  let memberAuths: string;
  let bannerTarget: string;
  const loginUser = JSON.parse(localStorage.getItem('currentUser') as string);

  const [openPopupCount, setOpenPopupCount] = useState(0);

  // // 회원 구분 (비로그인, 중개사, 일반)
  if (!loginUser) {
    memberAuths = 'NOMEMBER';
  } else if (loginUser && loginUser?.verify?.id) {
    memberAuths = 'BUSINESS';
  } else {
    memberAuths = 'MEMBER';
  }

  // 배너 노출 영역 구분
  // switch (location.pathname) {
  //   case '/':
  //     bannerTarget = 'HOME';
  //     break;
  //   case 'HOME':
  //     bannerTarget = 'HOME';
  //     break;
  //   case '/mygroup':
  //     bannerTarget = 'MY_GROUP';
  //     break;
  //   case '/localgroup':
  //     bannerTarget = 'TOWN';
  //     break;
  //   case '/coagency-coagnecy':
  //     bannerTarget = 'COAGENCY';
  //     break;
  //   case '/allposts':
  //     bannerTarget = 'ALLBOARD';
  //     break;
  //   // case '/allposts/free-board':
  //   //   bannerTarget = 'ALLBOARD';
  //   //   break;
  //   // case '/allposts/issue':
  //   //   bannerTarget = 'ALLBOARD';
  //   //   break;
  //   default:
  //     bannerTarget = '';
  //     break;
  // }

  // const fetchData = async () => {
  //   try {
  //     const res = await commonAxios.post(`${import.meta.env.VITE_API_BASE_URL}/popup/list`, {
  //       devices: ['PC'],
  //       read_auths: [memberAuths],
  //       target_pages: [bannerTarget],
  //     });

  //     // api 에러 나면 예외 처리 추가
  //     if (res && (res.status === 200 || res.status === 201)) {
  //       const imageArray = res.data.filter((item: any) => item.popup_type === 'image');
  //       const textArray = res.data.filter((item: any) => item.popup_type === 'text');
  //       const bottomArray = res.data.filter((item: any) => item.popup_type === 'image-bottom');

  //       setImageArray(imageArray);
  //       setImageUrlArray(imageArray.map((item: any) => item.pc_image));
  //       setImageUrlArrayLength(imageArray.length);

  //       setTextArray(textArray.map((item: any) => item.pc_content));
  //       setTextTitleArray(textArray.map((item: any) => item.title));
  //       setTextArrayLength(textArray.length);

  //       setBottomArray(bottomArray.map((item: any) => item.pc_image));
  //       setBottomTitleArray(bottomArray.map((item: any) => item.title));
  //       setBottomArrayLength(bottomArray.length);
  //     }
  //   } catch (err) {
  //     console.error('error-----------------', err);
  //     if (err) {
  //       // 오류 처리를 수행하세요
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (bannerTarget !== '') {
  //     fetchData();
  //   }
  // }, [location, bannerTarget]);
  // // 팝업 관련 코드 추가 (끝)

  // // 이미지 팝업
  // const [currentSlide, setCurrentSlide] = useState(0);
  // const [isOpenImg, setIsOpenImg] = useState(true);

  // const closePopupImg = () => {
  //   setIsOpenImg(false);
  // };

  // const handleNotTodayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     const tomorrow = new Date();
  //     tomorrow.setDate(new Date().getDate() + 1);
  //     localStorage.setItem('imgPopupCheck', tomorrow.toString());
  //   } else {
  //     localStorage.removeItem('imgPopupCheck');
  //   }
  // };

  // // 텍스트 팝업
  // const [isOpenText, setIsOpenText] = useState(true);
  // const [currentSlideText, setCurrentSlideText] = useState(0);

  // const closePopupText = () => {
  //   setIsOpenText(false);
  // };

  // const handleNotTodayChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     const tomorrow = new Date();
  //     tomorrow.setDate(new Date().getDate() + 1);
  //     localStorage.setItem('hideUntilText', tomorrow.toString());
  //   } else {
  //     localStorage.removeItem('hideUntilText');
  //   }
  // };

  // // BOTTOM 팝업
  // const [isOpenBottom, setIsOpenBottom] = useState(true);
  // const [currentSlideBottom, setCurrentSlideBottom] = useState(0);

  // const closePopupBottom = () => {
  //   setIsOpenBottom(false);
  // };

  // // 이미지, 텍스트 팝업 하나라도 있으면 부모 스크롤 막기
  // useEffect(() => {
  //   const checkPopups = () => {
  //     const imagePopup = document.getElementById('imagePopup');
  //     const textPopup = document.getElementById('textPopup');

  //     if (imagePopup || textPopup) {
  //       document.body.style.overflow = 'hidden';
  //     } else {
  //       document.body.style.overflow = 'unset';
  //     }
  //   };

  //   checkPopups();

  //   // DOM이 변경될 때마다 checkPopups 함수를 호출합니다.
  //   const observer = new MutationObserver(checkPopups);
  //   observer.observe(document, { childList: true, subtree: true });

  //   // 컴포넌트가 언마운트될 때 observer를 정리합니다.
  //   return () => observer.disconnect();
  // }, []);

  // const handleNotTodayChangeBottom = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     const tomorrow = new Date();
  //     tomorrow.setDate(new Date().getDate() + 1);
  //     localStorage.setItem('hideUntilBottom', tomorrow.toString());
  //   } else {
  //     localStorage.removeItem('hideUntilBottom');
  //   }
  // };
  // // // // 팝업 관련 코드 추가 (끝)

  return agent.includes('aipartnernet') ? (
    <div
      className={CommonStyles['loading-container']}
      style={{ zIndex: 99999, width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }}
    >
      <div className={CommonStyles['loading-spinner']} />
    </div>
  ) : (
    <div id="wrap">
      {isLogin ? (
        <div className={Styles.header}>
          <div className={Styles['header-top']}>
            <div className={cn(Styles['my-menu'], CommonStyles['divide-box'], Styles['divide-box'])}>
              {brokerVerifiedAt === null ? (
                <div className={Styles.menu}>
                  <Link
                    to={`${PATHCOMMON.MYBOARDBOARD}?tabtype=allpost`}
                    className={cn(Styles.news, path.includes('/myboard') ? Styles.on : '')}
                    title="내가 쓴 글"
                    onClick={scrollToTop}
                  >
                    <span>내가 쓴 글</span>
                  </Link>
                </div>
              ) : (
                <div className={Styles.menu}>
                  <Link
                    to={PATHCOMMON.MYBOARDCOAGENCY}
                    className={cn(Styles.news, path.includes('/myboard') ? Styles.on : '')}
                    title="내가 쓴 글"
                    onClick={scrollToTop}
                  >
                    내가 쓴 글
                  </Link>
                </div>
              )}
              {brokerVerifiedAt === null ? (
                <div className={Styles.menu}>
                  <Link
                    to={PATHCOMMON.BOOKMARKBOARD}
                    className={cn(Styles.bookmark, path.includes('/bookmark') ? Styles.on : '')}
                    title="북마크"
                    onClick={scrollToTop}
                  >
                    북마크
                  </Link>
                </div>
              ) : (
                <div className={Styles.menu}>
                  <Link
                    to={PATHCOMMON.BOOKMARKCOAGENCY}
                    className={cn(Styles.bookmark, path.includes('/bookmark') ? Styles.on : '')}
                    title="북마크"
                    onClick={scrollToTop}
                  >
                    북마크
                  </Link>
                </div>
              )}
              <div className={cn(Styles.menu, Styles['has-new'])}>
                <button
                  type="button"
                  className={cn(GTM_CLASS.Login.home.alarm, Styles.alarm, showAlim && Styles.on)}
                  title="알림"
                  onClick={() => {
                    setShowAlim(!showAlim);
                    commonstore.setSearchPalcehoder('');
                  }}
                >
                  알림
                </button>
                {showAlim && <AlimPopup showAlim={showAlim} setShowAlim={setShowAlim} />}
              </div>
              <div ref={dropdownRef} className={cn(Styles['my-profile'], Styles['tool-box'])}>
                <div>
                  {dropdownVisibility ? (
                    <button
                      type="button"
                      onClick={(e) => {
                        setDropdownVisibility(!dropdownVisibility);
                        commonstore.setSearchPalcehoder('');
                      }}
                      className={cn(Styles.user, Styles.up, CommonStyles['tool-btn'])}
                    >
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          backgroundImage: `url(${
                            infoMe?.profile_photo_path
                              ? infoMe?.profile_photo_path
                              : currentUser?.profile_photo_path || '/images/icon_profile.svg'
                          })`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                        }}
                        className={Styles['user-photo']}
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => {
                        setDropdownVisibility(!dropdownVisibility);
                        commonstore.setSearchPalcehoder('');
                      }}
                      className={cn(Styles.user, CommonStyles['tool-btn'])}
                    >
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          backgroundImage: `url(${
                            infoMe?.profile_photo_path
                              ? infoMe?.profile_photo_path
                              : currentUser?.profile_photo_path || '/images/icon_profile.svg'
                          })`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                        }}
                        className={Styles['user-photo']}
                      />
                    </button>
                  )}
                </div>
                <div style={{ position: 'relative' }}>
                  {dropdownVisibility && (
                    <Dropdown visibility={dropdownVisibility}>
                      <div>
                        <button type="button" onClick={() => navigate(PATHMYINFO.MYINFO)}>
                          내 정보 보기
                        </button>
                        {Config.SERVICE_USE_SETTING.JOIN_REQUEST_COMMUNITY === 'enabled' && (
                          <button type="button" onClick={() => navigate(PATHMYINFO.MYJOININGGROUP)}>
                            가입 신청 중인 모임
                          </button>
                        )}
                        <button type="button" onClick={logout}>
                          <i className={CommonStyles['icon-logout']} />
                          로그아웃
                        </button>
                      </div>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={Styles['header-left']}>
            <Link to="/" className={cn(GTM_CLASS.Login.home.logo, Styles.logo)} onClick={scrollToTop}>
              <span className={CommonStyles.a11y_hidden}>이실장넷</span>
            </Link>
            <div className={Styles['net-menu']}>
              <Link
                to={PATHALLPOSTS.MAIN}
                className={cn(Styles.board, path.includes('/allpost') || path === PATHAUTH.MAIN ? Styles.on : '')}
                onClick={scrollToTop}
                reloadDocument
              >
                전체 게시판
              </Link>
              <Link
                to="/mygroup"
                className={cn(
                  Styles.group,
                  GTM_CLASS.Login.home.myGroup,
                  path.includes('/mygroup') || serviceCode ? Styles.on : '',
                )}
                onClick={(event) => {
                  scrollToTop();
                  event.preventDefault();
                  window.location.href = '/mygroup';
                }}
              >
                내모임
              </Link>
              <Link
                to="/localgroup"
                className={cn(
                  Styles.community,
                  GTM_CLASS.Login.home.localGroup,
                  path.includes('/localgroup') ? Styles.on : '',
                )}
                onClick={scrollToTop}
                reloadDocument
              >
                우리동네 모임
              </Link>
              <Link
                // to={brokerVerifiedAt === null ? `${PATHAUTH.BROKERNEW}` : `${PATHCOAGENCY.MAIN}`}
                to={`${PATHCOAGENCY.MAIN}`}
                state="gnb"
                className={cn(
                  Styles.share,
                  GTM_CLASS.Login.home.coagency,
                  path.includes('/coagency-') ? Styles.on : '',
                )}
                onClick={scrollToTop}
              >
                공동중개
              </Link>
            </div>
          </div>
          <div className={Styles['header-right']}>
            {commonstore.isSearchPalcehoder.length > 0 ? (
              <div className={cn(Styles['search-box'], Styles.on)}>
                <input
                  ref={searchRef}
                  type="text"
                  className={Styles['search-area']}
                  placeholder={commonstore.isSearchPalcehoder}
                  onKeyDown={(e) => handleKeyPress(e)}
                  value={searchKeyword || ''}
                  onChange={(e) => handleSearchChange(e)}
                  onClick={onReadySearch}
                  autoComplete="off"
                />
              </div>
            ) : (
              <div className={cn(Styles['search-box'], searchon)}>
                <input
                  ref={searchRef}
                  type="text"
                  className={Styles['search-area']}
                  placeholder={searchon ? '다양한 모임을 검색해보세요.' : '모임 검색'}
                  onKeyDown={(e) => handleKeyPress(e)}
                  value={searchKeyword || ''}
                  onChange={(e) => handleSearchChange(e)}
                  onClick={onReadySearch}
                  autoComplete="off"
                />
              </div>
            )}
            <div className={Styles['family-service']}>
              <div className={Styles.site}>
                <a
                  href={`${import.meta.env.VITE_AIPARTNER_URL}`}
                  target="_blank"
                  className={cn(GTM_CLASS.Login.home.aiPartner, Styles['site-ai'])}
                  rel="noreferrer"
                >
                  <img src="/images/logo_aipartner.svg" alt="이실장 로고" />
                </a>
                <div
                  className={cn(
                    CommonStyles.tooltip,
                    CommonStyles['tooltip-line'],
                    Styles.tooltip,
                    Styles['tooltip-line'],
                  )}
                >
                  중개사님의 AI업무파트너 이실장!
                </div>
              </div>
              <div className={Styles.site}>
                <a
                  href={`${import.meta.env.VITE_AIPARTNER_PLUS_IB_URL}`}
                  target="_blank"
                  className={cn(GTM_CLASS.Login.home.aiPlus, Styles['site-aiplus'])}
                  rel="noreferrer"
                >
                  <img src="/images/logo_aiplus.svg" alt="이실장플러스 로고" />
                </a>
                <div
                  className={cn(
                    CommonStyles.tooltip,
                    CommonStyles['tooltip-line'],
                    Styles.tooltip,
                    Styles['tooltip-line'],
                  )}
                >
                  매물과 부동산 리포트를 한 번에 이실장플러스!
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={cn(Styles.header, Styles['logout-header'])}>
          <div className={Styles['header-left']}>
            <Link to="/" className={Styles.logo}>
              <span className={CommonStyles.a11y_hidden}>이실장넷</span>
            </Link>
          </div>
          <div className={Styles['header-right']}>
            <div className={Styles['family-service']}>
              <div className={Styles.site}>
                <a
                  href={`${import.meta.env.VITE_AIPARTNER_URL}`}
                  target="_blank"
                  className={cn(GTM_CLASS.NonLogin.home.aiPartner, Styles['site-ai'])}
                  rel="noreferrer"
                >
                  <img src="/images/logo_aipartner.svg" alt="이실장 로고" />
                </a>
                <div
                  className={cn(
                    CommonStyles.tooltip,
                    CommonStyles['tooltip-line'],
                    Styles.tooltip,
                    Styles['tooltip-line'],
                  )}
                >
                  중개사님의 AI업무파트너 이실장!
                </div>
              </div>
              <div className={Styles.site}>
                <a
                  href={`${import.meta.env.VITE_AIPARTNER_PLUS_URL}`}
                  target="_blank"
                  className={cn(GTM_CLASS.NonLogin.home.aiPlus, Styles['site-aiplus'])}
                  rel="noreferrer"
                >
                  <img src="/images/logo_aiplus.svg" alt="이실장플러스 로고" />
                </a>
                <div
                  className={cn(
                    CommonStyles.tooltip,
                    CommonStyles['tooltip-line'],
                    Styles.tooltip,
                    Styles['tooltip-line'],
                  )}
                >
                  매물과 부동산 리포트를 한 번에 이실장플러스!
                </div>
              </div>
            </div>
            <div className={cn(CommonStyles['divide-box'], Styles['divide-box'])}>
              <a
                href={SSO_PATHS.LOGIN}
                className={cn(GTM_CLASS.NonLogin.home.login, Styles['login-btn'])}
                target="_self"
              >
                로그인
              </a>
              <a
                href={SSO_PATHS.SIGNUP}
                className={cn(GTM_CLASS.NonLogin.home.join, Styles['login-btn'])}
                target="_self"
              >
                회원가입
              </a>
            </div>
            <a ref={sessionOutRef} href={Config.SSO_SESSION_EXPIRE} className={CommonStyles.a11y_hidden}>
              세션 아웃
            </a>
          </div>
        </div>
      )}

      <div
        className={cn(
          CommonStyles.container,
          (path === '/' || path.includes('auth') || path === SSO_PATHS.SIGNUP_COMPLETE || PATHLOCALROUP.MAKESELECT) &&
            container,
          'container',
        )}
        style={{ minHeight: '100vh - 320px' }}
      >
        <Outlet />
      </div>

      {/* <TextPopup
        isOpenText={isOpenText}
        textArray={textArray}
        textTitleArray={textTitleArray}
        textUrlArray={textUrlArray}
        closePopupText={closePopupText}
        handleNotTodayChangeText={handleNotTodayChangeText}
        currentSlideText={currentSlideText}
      /> */}

      {/* 텍스트 팝업 (시작) */}
      {/* {isOpenText === true && textArray.length ? (
        <div className="popup layer-pop">
          <div className="popup-wrap" style={{ maxWidth: '520px' }}>
            <button className="close-btn" type="button" onClick={closePopupText}>
              <span className="d-none">닫기</span>
            </button>
            <p className="title">{textTitle[currentSlideText]}</p>
            <Swiper
              // loop
              // autoplay={{
              //   delay: 3000,
              //   stopOnLastSlide: false,
              //   disableOnInteraction: false,
              // }}
              ref={swiperRefText}
              spaceBetween={50}
              slidesPerView={1}
              autoHeight
              onSlideChangeTransitionEnd={(swiper) => setCurrentSlideText(swiper.realIndex)}
            >
              {textSlides.map((text: string, index: number) => (
                <SwiperSlide key={index} style={{ maxWidth: '470px' }}>
                  <div
                    className="text"
                    style={{
                      display: 'block',
                      maxWidth: '470px',
                      maxHeight: '300px',
                      overflow: 'auto',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {text}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-button-wrap" style={{ bottom: '120px', right: '24px' }}>
              <div className="swiper-button-prev" onClick={prevSlideText}>
                {' '}
              </div>
              <div className="swiper-pagination">
                {currentSlideText + 1}/{textCount}
              </div>
              <div className="swiper-button-next" onClick={nextSlideText}>
                {' '}
              </div>
            </div>
            <div className="btn-wrap">
              <button className="close" type="button" onClick={closePopupText}>
                닫기
              </button>
              <div className="check-box-div">
                <div className="check-box">
                  <input
                    type="checkbox"
                    id="notToday2"
                    // checked={checked}
                    // onChange={(e) => setChecked(e.target.checked)}
                    checked={notTodayText}
                    onChange={handleNotTodayChangeText}
                  />
                  <label htmlFor="notToday2" className="label-info">
                    오늘하루 보지않기
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* 텍스트 팝업 (끝) */}

      {/* <ImagePopup
        isOpenImg={isOpenImg}
        imageArray={imageArray}
        closePopupImg={closePopupImg}
        handleNotTodayChange={handleNotTodayChange}
        currentSlide={currentSlide}
      /> */}

      {/* 이미지 팝업 (시작) */}
      {/* {isOpenImg === true && imageArray.length > 0 ? (
        <div className="popup layer-pop">
          <div className="popup-wrap">
            <button className="close-btn" type="button" onClick={closePopupImg}>
              <span className="d-none">닫기</span>
            </button>
            <p className="title">{imagesTitle[currentSlide]}</p>
            <div className="layer-content">
              <Swiper
                // loop
                // autoplay={{
                //   delay: 3000,
                //   stopOnLastSlide: false,
                //   disableOnInteraction: false,
                // }}
                ref={swiperRef}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                autoHeight
                onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <a href={imagesUrl[currentSlide]} target="_blank" rel="noopener noreferrer">
                      <img className="popup-img" src={image} alt={`테스트용 이미지 ${index}`} />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="swiper-button-wrap" style={{ bottom: '120px', right: '24px' }}>
              <div className="swiper-button-prev" onClick={nextSlide}>
                {' '}
              </div>
              <div className="swiper-pagination">
                {currentSlide + 1}/{imageCount}
              </div>
              <div className="swiper-button-next" onClick={prevSlide}>
                {' '}
              </div>
            </div>
            <div className="btn-wrap">
              <button className="close" type="button" onClick={closePopupImg}>
                닫기
              </button>
              <div className="check-box-div">
                <div className="check-box">
                  <input type="checkbox" id="notToday3" checked={notToday} onChange={handleNotTodayChange} />
                  <label htmlFor="notToday3" className="label-info">
                    오늘하루 보지않기
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* 이미지 팝업 (끝) */}

      {/* <BottomPopup
        isOpenBottom={isOpenBottom}
        bottomArray={bottomArray}
        bottomTitleArray={bottomTitleArray}
        bottomUrlArray={bottomUrlArray}
        closePopupBottom={closePopupBottom}
        handleNotTodayChangeBottom={handleNotTodayChangeBottom}
        currentSlideBottom={currentSlideBottom}
      /> */}

      {/* BOTTOM 팝업 (시작) */}
      {/* {isOpenBottom === true && imageBottomArray.length ? (
        <div className="bottom-sheet-pop popup">
          <button className="close" type="button" onClick={closePopupBottom}>
            <span className="d-none">닫기</span>
          </button>
          <Swiper
            loop
            autoplay={{
              delay: 3000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
            }}
            observer
            observeParents
            className="swiper-container"
            onSwiper={(swiper) => {
              swiperRefBottom.current = swiper;
            }}
            onSlideChange={(swiper) => {
              setCurrentSlideBottom(swiper.realIndex);
            }}
            // modules={[Autoplay, Navigation]}
            // speed={20000}
          >
            {imagesBottom.map((image, index) => (
              <SwiperSlide key={index}>
                <a href={imagesBottomUrl[currentSlideBottom]} target="_blank" rel="noopener noreferrer">
                  <img className="bottom-img" src={image} alt="테스트용 이미지" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-wrap" style={{ bottom: '60px', right: '4px' }}>
            <div className="swiper-button-prev" onClick={prevSlideBottom}>
              {' '}
            </div>
            <div className="swiper-pagination">
              {currentSlideBottom + 1}/{bottomCount}
            </div>
            <div className="swiper-button-next" onClick={nextSlideBottom}>
              {' '}
            </div>
          </div>
          <div className="btn-wrap">
            <div className="check-box-div">
              <div className="check-box">
                <input
                  type="checkbox"
                  id="notToday"
                  // checked={checked}
                  // onChange={(e) => setChecked(e.target.checked)}
                  checked={notTodayBottom}
                  onChange={handleNotTodayChangeBottom}
                />
                <label htmlFor="notToday" className="label-info">
                  오늘하루 보지않기
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* BOTTOM 팝업 (끝) */}

      <footer
        style={
          path === '/terms' ||
          path === '/privacy-policy' ||
          path.includes('/auth') ||
          path === SSO_PATHS.SIGNUP_COMPLETE
            ? { borderTop: 0 }
            : {}
        }
      >
        {path === '/help' && (
          <div className={FooterStyles['faq-to-inquiry-banner']}>
            <button type="button" className={FooterStyles.inner} onClick={() => navigate('/qna')}>
              <img src="/images/inquiry_banner.svg" alt="온라인문의 가기 배너 속 아이콘" />
              <p>
                원하시는 도움말을 찾지 못 하셨다면, <b className={ResetStyles['fw-b']}>‘온라인 문의’</b>를 진행해
                주세요!
              </p>
              <i className={cn(CommonStyles['icon-arrow-double'], CommonStyles['icon-right'])} />
            </button>
          </div>
        )}
        {path === '/terms' ||
          path === '/privacy-policy' ||
          path.includes('/auth') ||
          path === SSO_PATHS.SIGNUP_COMPLETE || (
            <>
              {lastNotice?.id && (
                <div className={FooterStyles['notice-section']}>
                  <div className={FooterStyles.inner}>
                    <div className={cn(FooterStyles.tit, ResetStyles.sfont600)}>공지사항</div>
                    <Link
                      to={`/notice-detail?id=${lastNotice.id}`}
                      className={cn(
                        FooterStyles.list,
                        FooterStyles['limit-line1'],
                        CommonStyles['limit-line1'],
                        CommonStyles['limit-line'],
                      )}
                      onClick={() => setShowAlim(false)}
                    >
                      {lastNotice?.title || ''}
                    </Link>
                    <button
                      type="button"
                      onClick={() => {
                        navigate('/notice');
                        setShowAlim(false);
                      }}
                    >
                      전체보기
                      <i className={cn(CommonStyles['icon-arrow'], CommonStyles['icon-right'])} />
                    </button>
                  </div>
                </div>
              )}
              <div className={FooterStyles['footer-wrap']}>
                <div className={FooterStyles['cs-center']}>
                  <p className={cn(CommonStyles.tit, FooterStyles.tit, ResetStyles.sfont600)}>고객센터</p>
                  <p className={FooterStyles.info}>
                    문의 접수 <span className={FooterStyles['fw-b']}>24시간</span>
                  </p>
                  <p className={FooterStyles.info}>
                    답변 시간 <span className={FooterStyles['fw-b']}>09:00 ~ 18:00</span> (주말, 공휴일 휴무)
                  </p>
                </div>
                <div className={FooterStyles['support-section']}>
                  <button
                    type="button"
                    className={FooterStyles.btn}
                    onClick={() => {
                      navigate('/qna');
                      setShowAlim(false);
                    }}
                  >
                    <p>
                      온라인문의 <i className={cn(CommonStyles['icon-arrow-org'], CommonStyles['icon-right'])} />
                    </p>
                    <i className={CommonStyles['icon-bubble-question']} />
                  </button>
                  <button
                    type="button"
                    className={cn(CommonStyles.btn, FooterStyles.btn)}
                    onClick={() => {
                      navigate('/help');
                      setShowAlim(false);
                    }}
                  >
                    <p>
                      도움말 <i className={cn(CommonStyles['icon-arrow-org'], CommonStyles['icon-right'])} />
                    </p>
                    <i className={CommonStyles['icon-helper']} />
                  </button>
                </div>
                <div className={FooterStyles['sns-section']}>
                  <a
                    href="https://blog.naver.com/aipartner"
                    target="_blank"
                    className={cn(FooterStyles['sns-icon'], FooterStyles.blog)}
                    rel="noreferrer"
                  >
                    <span>블로그</span>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UClA9INTxGBxp4R3Y40FPBPA"
                    target="_blank"
                    className={cn(FooterStyles['sns-icon'], FooterStyles.youtube)}
                    rel="noreferrer"
                  >
                    <span>유튜브</span>
                  </a>
                </div>
                <div className={FooterStyles['footer-link']}>
                  <div className={FooterStyles.left}>
                    <ul>
                      <li>
                        <a href="https://www.aipartner.com/html/terms" target="_blank" rel="noreferrer">
                          <span>서비스 이용약관</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.aipartner.com/html/privacy" target="_blank" rel="noreferrer">
                          <strong>개인정보 처리방침</strong>
                        </a>
                      </li>
                    </ul>
                    <ul className={cn(FooterStyles['company-info'], FooterStyles.right)}>
                      <li className={FooterStyles.info}>프롭티어(주)</li>
                      <li className={FooterStyles.info}>대표자 : 서동록</li>
                      <li className={FooterStyles.info}>서울특별시 서초구 방배로 107, 디엠타워3관 </li>
                      <li className={FooterStyles.info}>사업자번호 : 214-87-09557</li>
                      <li className={FooterStyles.info}>통신판매신고번호 : 제 2019-서울서초-0765</li>
                      <li className={cn(CommonStyles.w100p, FooterStyles.info)}>© proptier. ALL RIGHTS RESERVED.</li>
                    </ul>
                  </div>
                  <div className={cn(CommonStyles.right, CommonStyles.logo)}>
                    <img src="/images/logo_proptier.svg" alt="프롭티어 로고" />
                  </div>
                </div>
              </div>
            </>
          )}

        {/** 중개사 인증/ 서비스 이용약관/개인정보처리방침 footer */}
        {(path === '/terms' ||
          path === '/privacy-policy' ||
          path.includes('/auth') ||
          path === SSO_PATHS.SIGNUP_COMPLETE) && (
          <div
            className={cn(
              FooterStyles['simple-footer'],
              path.includes('/auth') || path === SSO_PATHS.SIGNUP_COMPLETE
                ? FooterStyles['bg-gray']
                : FooterStyles['bg-white'],
            )}
          >
            <ul className={FooterStyles['footer-link']}>
              <li>
                <a href="https://www.aipartner.com/html/terms" target="_blank" rel="noreferrer">
                  <span>서비스 이용약관</span>
                </a>
              </li>
              <li>
                <a href="https://www.aipartner.com/html/privacy" target="_blank" rel="noreferrer">
                  <strong>개인정보 처리방침</strong>
                </a>
              </li>
              <li>
                <Link to="/help">도움말</Link>
              </li>
              <li>
                <Link to="/qna">온라인 문의</Link>
              </li>
            </ul>
            <div className={FooterStyles['footer-copy']}>
              <p>© proptier. ALL RIGHTS RESERVED.</p>
            </div>
          </div>
        )}
      </footer>

      <ToastContainer position="bottom-center" toastClassName="custom-toast-success" />
    </div>
  );
});

export default memo(Layout);
